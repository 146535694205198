import React, {useEffect, useState} from 'react'
import {CheckCircle, Cancel, Info} from '@mui/icons-material'
import {SnackbarProvider as NotistackProvider, type SnackbarProviderProps} from 'notistack'
import {Portal} from '@mui/material'

/**
 * This provider collects snackbars that are enqueued from anywhere in the app,
 * displays them, and provided default setting for them.
 */
export function SnackbarProvider(props: SnackbarProviderProps) {
  // This sets a root container element for the snackbar component, which is needed to render the snackbars inside MUI Portal.
  // The setTimeout is to wait for the container to render before setting the domRoot prop.
  // By default, snackbars are rendered inside the body, behind things like Dialog/Popper
  const [containerEl, setContainerEl] = useState<HTMLElement>()
  useEffect(() => {
    setTimeout(() => {
      setContainerEl(document.getElementById('notistack-container')!)
    }, 100)
  }, [])

  return (
    <>
      <Portal>
        <div id="notistack-container" className="relative z-[1400]"></div>
      </Portal>
      <NotistackProvider
        domRoot={containerEl}
        iconVariant={{
          success: <CheckCircle className="mr-2" />,
          error: <Cancel className="mr-2" />,
          info: <Info className="mr-2 fill-blue-800" />,
        }}
        classes={{
          root: 'rounded-sm shadow-sm text-white',
          variantSuccess: 'bg-green-500',
          variantInfo: 'text-grey-800 bg-white',
        }}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...props}
      />
    </>
  )
}
