import {forwardRef, type Ref} from 'react'
import {IconButton, type IconButtonProps} from '@mui/material'

import {cs} from '~/utils'
import {Tooltip} from '../tooltip'

export type ActionIconBtnProps = IconButtonProps & {
  variant?: 'transparent' | 'white' | 'dark-transparent' | 'white-border'
  label: string
  size?: 'small' | 'medium'
}

/**
 * An icon button intended to be placed in the ActionBar to open Action options
 */
export const ActionIconBtn = forwardRef(function ActionBtn(
  props: ActionIconBtnProps,
  ref: Ref<any>,
) {
  const {className, variant = 'transparent', label, size = 'medium', ...rest} = props

  return (
    <Tooltip className="text-grey-900" title={label || ''}>
      <IconButton
        {...rest}
        ref={ref}
        className={cs(
          'ActionBtn',
          size === 'small' && 'h-10 w-10',
          size === 'medium' && 'h-12 w-12',
          'shrink-0 grow-0',
          variant === 'transparent' && 'bg-black/5 text-grey-900 hover:bg-black/10',
          variant === 'dark-transparent' && 'bg-black/20 text-white hover:bg-black/30',
          (variant === 'white' || variant === 'white-border') && 'bg-white hover:bg-grey-300',
          variant === 'white-border' && 'border border-solid border-grey-500',
          className,
        )}
        aria-label={label}
        color="inherit"
      />
    </Tooltip>
  )
})
