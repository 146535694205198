import axios from 'axios'
import {TokenDomain} from '@skyslope/auth-js'
import jwt_decode from 'jwt-decode'

import {config} from '~/utils'
import {AuthStore} from './store'
import {getUserManager} from './user-manager'

/**
 * An AWS Cognito endpoint authenticated with the Form's DS3 client credentials.
 * Returns a token that can be used to authenticate calls to DS3's User Service endpoints.
 */
export async function getCognitoToken() {
  return (
    await axios({
      url: `${config.apiBaseUrl}/auth/digisign/cognito`,
      method: 'GET',
    })
  ).data
}

export interface OktaPasswordPolicy {
  description: string
  minLength?: number
}

export interface TokenStore {
  formsToken?: string
  sklosuresToken?: string
  ds3Token?: string
  accountsApiToken?: string
}

const userManager = getUserManager()
export const authClient = userManager?._oktaAuth?.oktaAuth

export const buildAuthStoreFromAuthState = async (): Promise<AuthStore> => {
  const authState = await userManager?.getAuthState()
  const idClaims = authState?.idToken?.claims
  const accessClaims = authState?.accessToken?.claims
  const formsUserCheckComplete = Boolean(sessionStorage.getItem('formsUserCheckComplete'))

  return {
    loggedIn: authState?.isAuthenticated || false,
    userId: accessClaims?.delegated_user_id ?? idClaims?.sub ?? null,
    accountAction: null,
    userInfo: {
      firstName: idClaims?.first_name ?? '',
      lastName: idClaims?.last_name ?? '',
      email: idClaims?.email ?? '',
    },
    primeUserInfo: {
      SubscriberId: idClaims?.subscriber_id,
      UserType: idClaims?.prime_contact_type,
    },
    formsUserCheckComplete: formsUserCheckComplete,
  }
}

export const login = async (username: string, password: string) => {
  localStorage.removeItem('com.skyslope.logout')
  const transaction = await authClient?.signInWithCredentials({username, password})
  if (transaction?.status !== 'SUCCESS') {
    throw new Error('Invalid Login')
  }
  const res = await authClient?.token.getWithoutPrompt({sessionToken: transaction.sessionToken})
  authClient?.tokenManager.setTokens(res?.tokens || {})
  return res?.tokens ?? {}
}

export const getRegistrationForm = async () => {
  const formInfo = await axios({
    method: 'GET',
    url: `${config!.okta!.baseUrl}/api/v1/registration/form`,
  })
  const passwordPolicies = formInfo.data.profileSchema.properties.password.allOf
    .map((pp: OktaPasswordPolicy) => pp.description)
    .filter(Boolean)
  return {policyId: formInfo.data.policyId, passwordPolicies}
}

export interface OktaPayload {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const register = async (policyId: string, oktaPayload: OktaPayload) => {
  // deconstruct phoneNumber out because the okta payload doesn't expect it.
  await axios({
    method: 'POST',
    url: `${config!.okta!.baseUrl}/api/v1/registration/${policyId}/register`,
    data: {userProfile: oktaPayload},
  }).catch((e) => {
    throw new Error(
      e.response?.data?.errorCauses?.[0]?.errorSummary || 'Registration failed, please try again.',
    )
  })

  const loginUser = await login(oktaPayload.email, oktaPayload.password)

  return loginUser
}

export const loginWithRedirect = (originalUri?: string, loginHint?: string) => {
  userManager?.login({
    idp: '0oapx40qvxeQeNory0h7',
    loginHint: loginHint,
    originalUri: originalUri,
  })
}

export const getExistingToken = async () => userManager?.getAccessToken()
export const getSklosuresToken = async () =>
  userManager?.getAccessToken(TokenDomain.Sklosures, [
    'openid',
    'profile',
    'email',
    'address',
    'phone',
    'com.skyslope.groups',
    'com.skyslope.prime.subscriber',
  ])

export const getAccountsApiToken = async () =>
  userManager?.getAccessToken(TokenDomain.Accounts, [
    'openid',
    'profile',
    'email',
    'address',
    'phone',
  ])

/**
 * Handles ds3 tokens. First gets the ds3 token from storage. If the token does not have the
 * required user_id field for ds3, call their user service to provision a user, then refetch the token
 * from the server.
 * @returns the up to date ds3 token
 */
export const handleDs3Token = async () => {
  const token = await getDS3Token(false)
  try {
    const decoded = jwt_decode(token) as unknown as any
    if (!decoded.user_id) {
      await getDS3ClientToken({
        firstName: decoded.first_name,
        lastName: decoded.last_name,
        email: decoded.sub,
      })
      return await getDS3Token(true)
    }
    return token
  } catch (e: any) {
    return token
  }
}

/**
 * Fetches ds3 token from okta.
 * @param force forces user manager to fetch new token from server,
 * ensuring we have the latest token
 * @returns ds3 access token
 */
export const getDS3Token = async (force?: boolean) =>
  userManager?.getAccessToken(
    TokenDomain.DigiSign,
    [
      'com.skyslope.digisign.userid',
      'com.skyslope.forms.accountid',
      'com.skyslope.groups',
      'com.skyslope.prime.subscriber',
      'openid',
      'email',
    ],
    force,
  )
/**
 * This is a HACK. We don't currently use this token from ds3 user service, however calling this api
 * provisions the user manually so that any new tokens from okta have the required values
 * @param data for ds3 client token payload
 * @returns a ds3 user service token which we do not need
 */
export async function getDS3ClientToken(data: {
  firstName: string
  lastName: string
  email: string
}) {
  const token = await getCognitoToken()
  return (
    await axios({
      url: `${config.ds3ApiUrl}/client`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
  ).data.access_token
}

export const getTokens = async (): Promise<TokenStore> => {
  const formsToken = await getExistingToken()
  const ds3Token = await handleDs3Token()
  const accountsApiToken = await getAccountsApiToken()
  if (!formsToken) {
    throw new Error('Tried to fetch tokens before a session was established')
  }
  return {
    formsToken,
    ds3Token,
    accountsApiToken,
  }
}
