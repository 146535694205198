/* eslint-disable @typescript-eslint/no-unused-vars */
import {Component} from 'react'
import Image from 'next/future/image'
import {captureException} from '@sentry/react'

import {cs} from '~/utils'
import type {HtmlElProps} from '~/types'
import {GradientLayout} from '~/page-components'
import {AuthStoreContext, type AuthStore} from '~/auth'
import {OffersLogo} from '../offers-logo'

export type ErrorBoundaryProps = HtmlElProps & {
  /** This is for testing purposes only. */
  forceErrorUI?: boolean
}

/**
 * A error boundary to wrap the entire application
 */
export function ErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <AuthStoreContext.Consumer>
      {(authStore) => <InternalErrorBoundary {...props} authStore={authStore} />}
    </AuthStoreContext.Consumer>
  )
}

type InternalErrorBoundaryProps = ErrorBoundaryProps & {authStore: AuthStore | undefined}
class InternalErrorBoundary extends Component<
  InternalErrorBoundaryProps,
  {hasError: boolean; eventId: string | null}
> {
  constructor(props: InternalErrorBoundaryProps) {
    super(props)
    this.state = {hasError: false, eventId: null}
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    return {hasError: true}
  }

  componentDidCatch(error: any, errorInfo: any) {
    const eventId = captureException(error, {
      level: 'fatal',
      extra: {errorInfo},
      user: {id: this.props.authStore?.userId ?? undefined},
    })
    console.error(`FATAL CRASH - eventId: ${eventId}`)
    this.setState({eventId})
  }

  render(): any {
    const hasError = this.state.hasError || this.props.forceErrorUI
    return hasError ? <ErrorUI eventId={this.state.eventId} /> : this.props.children
  }
}

function ErrorUI(props: {eventId: string | null}) {
  const {eventId} = props
  return (
    <GradientLayout topLeft={<OffersLogo />}>
      <div
        className={cs(
          'shadow-blue-9 relative z-20 mt-24 rounded-xl bg-white text-grey-900',
          'px-8 py-4 md:py-20',
        )}
      >
        <Image
          src="/static/images/link-expired/dog-disconnected.svg"
          width={961}
          height={289}
          alt=""
          className="mx-auto"
        />

        <div className="mx-auto mt-8 max-w-xl">
          <h1 className="font-sans text-3xl">Oops!</h1>
          <div className="flex flex-col gap-4 pt-6 text-base text-grey-800 md:text-xl">
            <p>Looks like something went wrong on our side.</p>
            <p>
              Our team has been notified, but you can still{' '}
              <a
                href="https://support.skyslope.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact support
              </a>
              .
            </p>
            {eventId && <p className="text-base italic">Event ID: {eventId}</p>}
          </div>
        </div>
      </div>
    </GradientLayout>
  )
}
