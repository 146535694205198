import axios from 'axios'
import {config} from '~/utils'
import {getAccountsApiToken} from '../auth/okta'
import type {FormsUserProfile} from '../auth/auth-types'
import {userManager} from '../auth/user-manager'

export const getExistingToken = async () => userManager.getAccessToken()

export type GetOrCreateUserProfilePayload = Partial<FormsUserProfile> & {
  userId: string
}
export type GetOrCreateUserProfileResult = GetUserProfileResult

/**
 * Function to check if a user Forms exists first before creating.
 * Borrowed from Breeze, kept it here for future work
 *
 * @param userId okta user id
 * @param firstName
 * @param lastName
 * @param email
 * @returns user profile result
 */
export async function getOrCreateUserProfile(
  payload: GetOrCreateUserProfilePayload,
): Promise<GetOrCreateUserProfileResult> {
  try {
    return await getUserProfile(payload.userId)
  } catch (err: any) {
    if (err.response?.status === 404) {
      return await manipulateUserProfile('create', payload)
    }
    throw err
  }
}

export type GetUserProfileResult = FormsUserProfile | null

export async function getUserProfile(userId: string | null): Promise<GetUserProfileResult> {
  if (!userId) return null

  const [token] = await Promise.all([getExistingToken()])
  const accountsApiToken = await getAccountsApiToken()
  const res = await axios({
    method: 'GET',
    url: `${config.formsUrl}/users/api/user-profiles/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      // We need to be able to GET a profile for another user that you have access to
      'x-accounts-authorization': accountsApiToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  return res.data.result
}

export type CreateUserProfilePayload = Partial<FormsUserProfile> & {userId: string | null}
export type UpdateUserProfilePayload = FormsUserProfile & {userId: string | null}
export type UpdateUserProfileResult = FormsUserProfile
export async function updateUserProfile(payload: UpdateUserProfilePayload) {
  return manipulateUserProfile('update', payload)
}

/* eslint-disable no-redeclare */
//  This is typescript method overloading */
// prettier-ignore
async function manipulateUserProfile(type: 'create', payload: CreateUserProfilePayload): Promise<UpdateUserProfileResult>
// prettier-ignore
async function manipulateUserProfile(type: 'update', payload: UpdateUserProfilePayload): Promise<UpdateUserProfileResult>
async function manipulateUserProfile(
  type: 'create' | 'update',
  payload: CreateUserProfilePayload,
): Promise<UpdateUserProfileResult> {
  const token = await getExistingToken()
  const {userId, ...restPayload} = payload

  const res = await axios({
    method: type === 'create' ? 'POST' : 'PUT',
    url: `${config.formsUrl}/users/api/user-profiles/${type === 'create' ? '' : userId}`,
    data: restPayload, // {...payload, areTermsAccepted: true},
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  return res.data.result
}

export async function getTrrebMember(trrebMemberId: string): Promise<TrrebMember> {
  const token = await getExistingToken()
  const res = await axios({
    method: 'GET',
    url: `${config.formsUrl}/users/api/trreb/member/${trrebMemberId}`,
    headers: {Authorization: `Bearer ${token}`},
  })
  return res.data.result
}

export interface TrrebMember {
  title: string
  officeId: string
  lastName: string
  uid: string
  memberId: string
  officeName: string
  firstName: string
  phone: string
  officePhone: string
  officeAddress: string
  officeCity: string
  officeProvince: string
  officePCode: string
  lud: string
  email: string
}

export async function linkTrrebUser(userId: string): Promise<string> {
  const token = await getExistingToken()
  const res = await axios({
    method: 'PUT',
    url: `${config.formsUrl}/users/api/rpc/user/relink`,
    headers: {Authorization: `Bearer ${token}`},
    data: {
      memberId: userId,
    },
  })
  return res.data.result
}
