import {createTheme, type ThemeOptions} from '@mui/material/styles'
import {deepmerge} from '@mui/utils'
import {macheTheme, colors} from '@skyslope/mache'

export const theme = createTheme(
  deepmerge(macheTheme, {
    // These breakpoints are taken directly from Tailwind
    // See: https://tailwindcss.com/docs/screens
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536,
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: {
            verticalAlign: 'text-top',
          },
        },
      },
      // This is to fix Maché
      MuiInputLabel: {
        defaultProps: {
          shrink: undefined,
          disableAnimation: undefined,
          variant: 'outlined',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 9999,
            textTransform: 'none',
            padding: '14px 24px',
          },
          contained: {
            padding: undefined,
          },
          containedPrimary: {
            // Gradient is originally based in hex,  #533DB1 -> blue500
            // We convert to HSL here to easily create lighter colors for the hover background
            background: `linear-gradient(to right, hsl(251 48% 48%), hsl(207, 93%, 52%))`,
            backgroundColor: undefined,
            padding: undefined,

            '&:hover': {
              background: 'linear-gradient(to right, hsl(251 48% 55%), hsl(207, 93%, 59%))',
            },
            '&:disabled': {
              background: colors.grey[500],
            },
          },
          containedSecondary: {
            padding: undefined,
            '&:active': {
              backgroundColor: '#fff',
            },
            '&:focus': {
              backgroundColor: '#fff',
            },
          },
          outlined: {
            '&:active': {
              backgroundColor: '#fff',
            },
            '&:focus': {
              backgroundColor: '#fff',
            },
          },
          outlinedPrimary: {
            '&:active': {
              backgroundColor: '#fff',
            },
            '&:focus': {
              backgroundColor: '#fff',
            },
          },
          text: {
            padding: undefined,
          },
          textPrimary: {
            padding: undefined,
          },
        },
        defaultProps: {
          disableRipple: false,
          color: 'primary',
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: '16px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            lineHeight: 1,
          },
        },
      },
    },
  } as ThemeOptions),
)

/* eslint-disable no-unused-vars */
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    '2xl': true
  }
}
