import {offersApi} from '~/auth/api-clients'

/**
 * Processes pending invites, if any. Uses api service token.
 * We do not care about the result from here.
 */
export async function processPendingInvites(email: string): Promise<any> {
  const res = await offersApi.post<any>(`/process-invites`, {email})
  return res
}
