import {type ReactNode} from 'react'
import ogcs from 'classnames'

import type {HtmlElProps} from '~/types'
import {cs} from '~/utils'

export type GoBackHeaderProps = HtmlElProps & {
  /** Title displayed in the header */
  title?: string
  /** The element rendered in the top left part of this header */
  backBtn?: ReactNode
  /**
   * Control positioning to keep this bar at the top of the page
   * when the user scrolls on large screens. (default: 'fixed')
   */
  lgScreenPos?: 'relative' | 'fixed' | 'absolute'
  classes?: {
    root?: string
    title?: string
    backBtnSeparator?: string
  }
  rightSideContent?: ReactNode
}

/**
 * A white bar at the top of the page that contains a title and optionally
 * a button to bo back somewhere.  By default it also has a blue gradient
 * displayed beneath it.
 *
 * Often used in conjunction with `HeaderGoBackBtn`.
 */
export function GoBackHeader(props: GoBackHeaderProps) {
  const {
    className,
    title,
    backBtn,
    lgScreenPos = 'fixed',
    classes = {},
    rightSideContent,
    ...rest
  } = props

  return (
    <header
      {...rest}
      className={cs(
        'GoBackHeader',
        'flex items-center',
        'h-21 w-full md:h-[72px]',
        'bg-white py-3 px-3 shadow-sm md:py-6',
        'relative z-20',
        lgScreenPos === 'fixed' && 'lg:fixed',
        lgScreenPos === 'absolute' && 'lg:absolute',
        lgScreenPos !== 'relative' && 'lg:top-0 lg:left-0 lg:right-0',
        className,
        classes.root,
      )}
    >
      {backBtn && (
        <>
          {backBtn}
          {title && (
            //  This is the vertical divider bar between the back btn and the title
            <div
              className={cs(
                '-my-1 mr-7 ml-3 self-stretch border-l border-grey-500',
                classes.backBtnSeparator,
              )}
              aria-hidden={true}
            />
          )}
        </>
      )}
      {title && <h2 className={ogcs('text-xl leading-6 text-grey-900', classes.title)}>{title}</h2>}
      {rightSideContent && <div className="ml-auto text-right">{rightSideContent}</div>}
    </header>
  )
}
