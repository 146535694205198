import getConfig from 'next/config'
import {getEnvironmentSettings} from '@skyslope/auth-config'

// Constants
export type AppEnvironment = 'integ' | 'staging' | 'prod'
export const appEnvironments: Record<string, AppEnvironment> = {
  INTEG: 'integ',
  STAGING: 'staging',
  PROD: 'prod',
}

export type EnvConfig = {
  env: AppEnvironment
  baseUrl: string
  apiBaseUrl: string
  formsUrl: string
  ds3ApiUrl: string
  formsApi: string
  breezeUrl: string
  primeUrl: string
  accountsUrl: string
  adminUrl: string
  okta: {
    env: AppEnvironment
    baseUrl: string
    clientId: string
    formsServer: string
    primeIdp: string
    requestContext: string
  }
  heapId: string
  microsoftClarityId: string
  oktaLoginWidget: string
  mlsService: string
  launchDarklyId: string
}

const {publicRuntimeConfig} = getConfig()
// ENV specific constants
const dockerEntryPointEnv = publicRuntimeConfig.NEXT_PUBLIC_ENV
export const ENV = Object.values(appEnvironments).includes(dockerEntryPointEnv as AppEnvironment)
  ? (dockerEntryPointEnv as AppEnvironment)
  : 'integ'

const configByEnv: Record<AppEnvironment, EnvConfig> = {
  integ: {
    env: 'integ',
    baseUrl: 'https://integ-offers.skyslope.com',
    apiBaseUrl: 'https://integ-offers.skyslope.com/offers-api',
    formsUrl: 'https://integ-forms.skyslope.com',
    ds3ApiUrl: 'https://integ-digisign3.skyslope.com/api',
    formsApi: 'https://integ-forms.skyslope.com/api',
    breezeUrl: 'https://integ-breeze.skyslope.com',
    primeUrl: 'https://integ.skyslope.com',
    accountsUrl: 'https://accounts-integ.skyslope.com/api',
    adminUrl: 'https://integ-admin.skyslope.com/',
    okta: {
      env: 'integ',
      baseUrl: 'https://id-integ.skyslope.com',
      clientId: '0oahv3tjhhoouOWFd357',
      formsServer: 'aus1589ztbT5gAqTt357',
      primeIdp: '0oa154s1ybu3Lbyo6357',
      requestContext: '/home/oidc_client/0oahv3tjhhoouOWFd357/aln177a159h7Zf52X0g8',
    },
    heapId: '355210786',
    microsoftClarityId: 'cfwcvnbwls',
    oktaLoginWidget: '/',
    mlsService: 'https://integ-mls-service.skyslope.com',
    launchDarklyId: '620d3ba69533c014a015e2b1',
  },
  staging: {
    env: 'staging',
    baseUrl: 'https://staging-offers.skyslope.com',
    apiBaseUrl: 'https://staging-offers.skyslope.com/offers-api',
    formsUrl: 'https://staging-forms.skyslope.com',
    ds3ApiUrl: 'https://staging-digisign3.skyslope.com/api',
    formsApi: 'https://staging-forms.skyslope.com/api',
    breezeUrl: 'https://staging-breeze.skyslope.com',
    primeUrl: 'https://staging.skyslope.com',
    accountsUrl: 'https://accounts-staging.skyslope.com/api',
    adminUrl: 'https://staging-admin.skyslope.com/',
    okta: {
      env: 'staging',
      baseUrl: 'https://id-staging.skyslope.com',
      clientId: '0oa17c0xy4phWN6Ik0h8',
      formsServer: 'auspx3z6bi3weJs200h7',
      primeIdp: '0oapx40qvxeQeNory0h7',
      requestContext: '/home/oidc_client/0oa17c0xy4phWN6Ik0h8/aln5z7uhkbM6y7bMy0g7',
    },
    heapId: '1691345378',
    microsoftClarityId: 'cfwcpz4x95',
    oktaLoginWidget: '/',
    mlsService: 'https://staging-mls-service.skyslope.com',
    launchDarklyId: '620d3bb21426521502693354',
  },
  prod: {
    env: 'prod',
    baseUrl: 'https://offers.skyslope.com',
    apiBaseUrl: 'https://offers.skyslope.com/offers-api',
    formsUrl: 'https://forms.skyslope.com',
    ds3ApiUrl: 'https://digisign3.skyslope.com/api',
    formsApi: 'https://forms.skyslope.com/api',
    breezeUrl: 'https://breeze.skyslope.com',
    primeUrl: 'https://app.skyslope.com',
    accountsUrl: 'https://accounts.skyslope.com/api',
    adminUrl: 'https://admin.skyslope.com/',
    okta: {
      env: 'prod',
      baseUrl: 'https://id.skyslope.com',
      clientId: '0oa72k8d9ohOeOJJI4x7',
      formsServer: 'aus25q3otn0cUfwED4x6',
      primeIdp: '0oa25qgk2HCoSakYh4x6',
      requestContext: '', // Not needed for prod since we do not render the widget in our app
    },
    heapId: '3954488189',
    microsoftClarityId: 'cfwc751pl8',
    oktaLoginWidget: 'https://skyslope.com/offer-management-login/',
    mlsService: 'https://mls-service.skyslope.com',
    launchDarklyId: '620d3b924617e314a32f8943',
  },
}

if (process.env.NEXT_PUBLIC_LOCAL_OM_API_BASE) {
  configByEnv[ENV].apiBaseUrl = process.env.NEXT_PUBLIC_LOCAL_OM_API_BASE
}

export const config = configByEnv[ENV]

export const envSettings = getEnvironmentSettings(config.env as any)
