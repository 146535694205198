import {captureException} from '@sentry/react'

import {ErrorCodes} from '~/utils'
import {useAuthStore} from '~/auth'
import {useSnackbar} from '../use-snackbar'

type ErrorReporterOptions = {
  snackbar: boolean
}

export function useErrorReporter(code: ErrorCodes) {
  const snackbar = useSnackbar()
  const authStore = useAuthStore()

  function reportError(e: Error, options: ErrorReporterOptions = {snackbar: true}) {
    let eventId = ''
    if (process.env.NODE_ENV !== 'development') {
      eventId = captureException(e, {
        level: 'error',
        extra: {code},
        user: {id: authStore?.userId ?? undefined},
      })
    }

    console.error(`ERROR - code: ${code} - eventId: ${eventId || '<not reported>'}`)
    if (options.snackbar) snackbar.error({code})
  }

  return {reportError}
}
