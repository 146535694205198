import {config} from '../env-constants'

export function heapInit() {
  if (typeof window === 'undefined' || window.heap?.loaded) return
  if (process.env.NODE_ENV === 'development') return

  window.heap = window.heap || []
  window.heap.load = function (appId: string, configuration: any) {
    window.heap.appid = appId
    window.heap.config = configuration || {}
    const scriptEl = document.createElement('script')
    scriptEl.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js'
    scriptEl.async = true
    document.head.appendChild(scriptEl)
    const methods = [
      'addEventProperties',
      'addUserProperties',
      'clearEventProperties',
      'identify',
      'resetIdentity',
      'removeEventProperty',
      'setEventProperties',
      'track',
      'unsetEventProperty',
    ]
    methods.forEach((m) => {
      window.heap[m] = function () {
        window.heap.push([m].concat(Array.prototype.slice.call(arguments, 0)))
      }
    })
  }
  window.heap.load(config.heapId)
}

export type HeapProps = {
  userId: string
  email: string
  SubscriberId?: number
  UserType?: string
}

export function heapIdentify(props: HeapProps) {
  if (window.heap) {
    window.heap.identify(props.userId)
    window.heap.addUserProperties({
      email: props.email,
      client_app: 'offer-management',
      SubscriberId: props.SubscriberId,
      UserType: props.UserType,
      IsOfferManagementUser: true,
    })
  }
}
