import {useQuery, type UseQueryOptions} from 'react-query'
import {type AxiosError} from 'axios'

import {getUserProfile, type GetUserProfileResult} from '~/api'
import {ErrorCodes} from '~/utils'
import {useAuthStore} from '~/auth'
import {useUpdateAuthStore} from '../../_mutations/files-api/use-update-user-profile'
import {useErrorReporter} from '../../use-error-reporter'

export const getUserProfileKey = 'use-get-or-create-user-profile'

/**
 * This query fetches the Forms user profile for the
 * currently signed in user.
 */
export function useGetUserProfile(
  onBehalfOf?: string,
  options: UseQueryOptions<GetUserProfileResult, AxiosError> & {snackbar?: boolean} = {},
) {
  const {userId} = useAuthStore()
  const {reportError} = useErrorReporter(ErrorCodes.GetUserProfile)
  const {queryKey, queryFn} = getUserProfileQuery(onBehalfOf || userId)
  const updateAuthStore = useUpdateAuthStore()

  return useQuery<GetUserProfileResult, AxiosError>(queryKey, queryFn, {
    ...options,
    enabled: !!userId || !!onBehalfOf,
    onError(e) {
      options?.onError?.(e)
      reportError(e, {snackbar: options.snackbar ?? true})
    },
    onSuccess(newData) {
      options?.onSuccess?.(newData)
      if (!onBehalfOf) {
        updateAuthStore(newData)
      }
    },
  })
}

export function getUserProfileQuery(userId: string | null) {
  return {
    queryKey: [getUserProfileKey, userId],
    queryFn: () => getUserProfile(userId),
  }
}
