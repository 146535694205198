import {SupportedIDPs} from '@skyslope/offers-shared'

import {offersApi} from '~/auth/api-clients'

/**
 * Syncs Forms User Profile with User's Info from various MLS APIs.
 * We do not care about the result from here.
 */
export async function syncUserWithMls(idp: SupportedIDPs, mlsLoginId?: string): Promise<void> {
  await offersApi.post<void>(`/mls/sync`, {idp, mlsLoginId})
}
