import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

import {config} from '~/utils/env-constants'

export function sentryInit() {
  if (process.env.NODE_ENV === 'development') return

  Sentry.init({
    dsn: 'https://c1badbac65474f8ba1d2047e00090f5b@o460680.ingest.sentry.io/6166678',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: config.env,
    tracesSampleRate: 0.5,
    normalizeDepth: 5,
    beforeSend(event) {
      if (event.request?.url) {
        event.request.url = event.request.url.split('#')[0]
      }
      return event
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Session Ending/Sending to Login
      'Error: Request failed with status code 401',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /fast\.trychameleon\.com/i,
    ],
  })
}

export const trackError = (err: any, extraArgs: any = {}) => {
  Sentry.setExtra('apiRequest', err.request)
  Sentry.setExtra('apiResponseData', err.response?.data)
  Sentry.captureException(err, {extra: extraArgs})
}
