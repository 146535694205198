import {useMutation, useQueryClient, type UseMutationOptions} from 'react-query'
import {type AxiosError} from 'axios'

import {updateUserProfile, type UpdateUserProfilePayload, type UpdateUserProfileResult} from '~/api'
import {ErrorCodes} from '~/utils'
import {useAuthStore} from '~/auth'
import {useSnackbar} from '~/hooks'
import type {FormsUserProfile} from '~/auth/auth-types'
import {useErrorReporter} from '../../use-error-reporter'
import {getUserProfileQuery} from '../../_queries'

type HookPayload = Omit<Partial<UpdateUserProfilePayload>, 'userId'>

export function useUpdateUserProfile(
  options: UseMutationOptions<UpdateUserProfileResult, AxiosError, HookPayload> = {},
) {
  const {reportError} = useErrorReporter(ErrorCodes.UpdateUserProfile)
  const {userId} = useAuthStore()
  const query = getUserProfileQuery(userId)
  const updateAuthStore = useUpdateAuthStore()
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()

  return useMutation(
    (payload: HookPayload) => {
      const oldData = queryClient.getQueryData(query.queryKey) as FormsUserProfile
      const newData = {...oldData, ...payload, userId}
      return updateUserProfile(newData)
    },
    {
      ...options,
      async onMutate(payload) {
        await queryClient.cancelQueries(query)

        const oldData = queryClient.getQueryData(query.queryKey) as FormsUserProfile
        const newData = {...oldData, ...payload, userId}
        queryClient.setQueryData(query.queryKey, newData)

        updateAuthStore(payload)
        return {oldData}
      },
      async onSettled() {
        const query = getUserProfileQuery(userId)
        queryClient.invalidateQueries(query)
      },
      onError(e, payload, context) {
        options?.onError?.(e, payload, context)

        const showUsernameInUseError =
          e.response?.data?.errors && e.response?.data.errors[0].includes('login')
        if (showUsernameInUseError) snackbar.error({message: 'This username is already in use.'})

        reportError(e, {snackbar: !showUsernameInUseError})

        // Undo the optimistic update
        queryClient.setQueryData(query.queryKey, context?.oldData)
        updateAuthStore(context?.oldData)
      },
    },
  )
}

export function useUpdateAuthStore() {
  const {userInfo, update} = useAuthStore()

  function updateAuthStore(payload: HookPayload | FormsUserProfile | undefined | null) {
    // I know, this is ugly. We did not need this previously because we were using the first
    // and last name from TRREB Clareity. But now that we are using TRREB AMP, they do not give us
    // first or last name, so it is up to us to fetch it ourselves.
    //
    // Forms does this when we call the relink endpoint, it updates the user profile directly.
    // We should not update our auth info (which is used to render the user menu) until we are done linking.
    //
    // I did try to use the trrebRedirectUrlAtom to determine if it was linking
    // or not, but the atom is cleared before we get to this update function.
    const isTrrebUserLinking = payload?.firstName?.includes('.invalid')

    if (!isTrrebUserLinking) {
      update({
        userInfo: {
          firstName: payload?.firstName ?? userInfo.firstName,
          lastName: payload?.lastName ?? userInfo.lastName,
          email: payload?.email ?? userInfo.email,
          phoneNumber: payload?.primaryPhoneNumber ?? userInfo.phoneNumber,
        },
      })
      return
    }
  }

  return updateAuthStore
}
