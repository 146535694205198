import {Button} from '@mui/material'
import {AccountCircle, ArrowDropDown} from '@mui/icons-material'
import {SkyslopeAppsButton, useAnchorEl} from '@skyslope/mache'
import {useAtomValue} from 'jotai'

import {isFormsUserAtom} from '~/state'
import {cs, ENV, format} from '~/utils'
import {useIsPrimeUser} from '~/auth'
import {
  useAuthHelpers,
  useCheckUserRole,
  useFeatureFlags,
  useGetUserProfile,
  usePreferredIDP,
  useRouter,
} from '~/hooks'
import {HelpBtn} from './help-btn'
import {HeaderMenu} from './header-menu'
import {HeaderMenuItem} from './header-menu-item'

export type HeaderUserSettingsProps = {
  classes?: {
    helpWrapper?: string
    userNameBtn?: string
  }
}

/**
 * A component that displays the help button, and a menu with the
 * usr's first and last name that contains user specific actions
 * (including logout).
 */
export function HeaderUserSettings(props: HeaderUserSettingsProps) {
  const BROKER_ADMIN_ROLE = 'd88de0bd-7eac-4b47-b81b-a403c0390af4'
  const {classes = {}} = props

  const {routes} = useRouter()
  const {data: userProfile} = useGetUserProfile()
  const {logout, authState, isAuthenticated} = useAuthHelpers()
  const {anchorEl, close, handleClick, isOpen} = useAnchorEl()
  const flags = useFeatureFlags()
  const isFormsUser = useAtomValue(isFormsUserAtom)
  const isPrimeUser = useIsPrimeUser()
  const isUserBrokerAdmin = useCheckUserRole(isAuthenticated, BROKER_ADMIN_ROLE).data ?? false

  const {preferredIDP} = usePreferredIDP()
  const isFormsMenuEnabled =
    (preferredIDP && flags.isFormsMenuEnabled?.includes(preferredIDP)) ||
    flags.isFormsMenuEnabled?.includes('all')

  if (authState !== 'logged-in') return null

  return (
    <div className="flex shrink-0 flex-nowrap items-center">
      <HelpBtn className={classes.helpWrapper} />
      {/* To avoid confusion. There is a way for non-Prime Stellar/RECO/Whatever MLS users to see Forms in the App Drawer. 
          Since they are not Prime user we need to hide the Prime option, but Forms should always show in this scenario. */}
      {(isPrimeUser || isFormsMenuEnabled) && (
        <SkyslopeAppsButton
          linkEnv={ENV as any}
          show={{
            prime: isPrimeUser,
            forms: true,
            books: isPrimeUser,
          }}
        />
      )}
      <Button
        color="primary"
        startIcon={<AccountCircle />}
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
        className={cs('hidden md:inline-flex', classes.userNameBtn)}
      >
        {format.name({firstName: userProfile?.firstName, lastName: userProfile?.lastName})}
      </Button>

      <HeaderMenu
        onClose={close}
        onClick={close}
        open={isOpen}
        anchorEl={anchorEl}
        PaperProps={{
          // @ts-ignore
          'data-testid': 'header-user-settings',
        }}
      >
        <HeaderMenuItem href={routes.seller.myTeam()}>My Team</HeaderMenuItem>
        {isUserBrokerAdmin && (
          <HeaderMenuItem href={routes.external.brokerageAdmin()}>
            Brokerage Management
          </HeaderMenuItem>
        )}
        <HeaderMenuItem
          preventClientSideRouting
          href={isFormsUser ? routes.external.formsAccount() : routes.seller.account()}
        >
          Account Settings
        </HeaderMenuItem>
        <HeaderMenuItem onClick={logout}>Logout</HeaderMenuItem>
      </HeaderMenu>
    </div>
  )
}
