import {atom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'
import {type SupportedIDPs} from '@skyslope/offers-shared'

import {sessionStorage} from './session-storage'

/** This user was directed to Offers from Forms this session */
export const isFormsUserAtom = atomWithStorage('isFormsUser', false, sessionStorage)

export const shouldProcessInvitesAtom = atomWithStorage<boolean>(
  'shouldProcessInvites',
  true,
  sessionStorage,
)
export const hasSyncedUserProfileWithMlsThisSessionAtom = atomWithStorage(
  'hasSyncedUserProfileWithMls',
  false,
  sessionStorage,
)

/** The preferred Identity Provider (IDP) of this user  */
export const idpAtom = atomWithStorage<SupportedIDPs | null>('idp', null)

/** We need to save this to local storage because the other redirect url uses session storage */
export const trrebUserLinkingRedirectUrlAtom = atomWithStorage<string | null>(
  'trrebUserLinkingRedirectUrl',
  null,
)

export const previousUrlAtom = atom<string | null>(null)
