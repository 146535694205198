import {useAuth} from '@skyslope/auth-react'
import {getIsSSR} from '@skyslope/utils'

import {useMonitorOkta} from './use-monitor-okta'

export type AuthState = 'auth-loading' | 'logged-in' | 'logged-out'

/**
 * Tells you if auth is loading, is the user is logged in, or if the user is logged out
 */
export function useAuthState() {
  const {authState: authInfo} = useAuth()
  const {isOktaCheckingForToken} = useMonitorOkta()

  const isSSR = getIsSSR()
  const haveChecksPassed = !isSSR && !isOktaCheckingForToken

  const finalAuthState: AuthState = !haveChecksPassed
    ? 'auth-loading'
    : authInfo.isAuthenticated
      ? 'logged-in'
      : 'logged-out'

  return {
    /** Is auth loading, is the user logged in, or is the user logged out? */
    authState: finalAuthState,
    /** Raw auth state from SkySlope Auth. DOES NOT TAKE INTO ACCOUNT WHEN AUTH IS LOADING! */
    isAuthenticated: authInfo.isAuthenticated,
  }
}
