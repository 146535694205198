import {useCallback} from 'react'
import {type SupportedIDPs} from '@skyslope/offers-shared'
import {envSettings} from '~/utils'

import {getUserManager, useAuthStore, validateIDP as _validateIDP} from '~/auth'
import {useAuthState} from './use-auth-state'

/**
 * Returns simple state values related to authentication.
 *
 * Note that `authState !== 'logged-in'` is NOT the same thing as `authState === 'logged-out'`
 * and visa versa. Having `authState !== 'logged-in'` could mean that we are currently
 * checking to determine if we're logged in (`authState === 'auth-loading'`), or it
 * could mean we are logged out (`authState === 'logged-out'`).
 */
export function useAuthHelpers() {
  const {authState, isAuthenticated} = useAuthState()
  const {reset: resetAuthStore} = useAuthStore()

  const validateIDP = useCallback((idp: SupportedIDPs | undefined) => !!_validateIDP(idp).idp, [])

  const loginSSOUser = useCallback((idp: SupportedIDPs) => {
    const {key, idp: validatedIDP} = _validateIDP(idp)
    if (key && validatedIDP === 'trreb') {
      const userManager = getUserManager()
      userManager.login({idp: envSettings.okta?.idps?.associations?.['trreb_amp']})
    } else if (key) {
      const userManager = getUserManager()
      userManager.login({idp: key})
    }
  }, [])

  const logout = useCallback(async () => {
    const userManager = getUserManager()
    await userManager.startLogout()
    resetAuthStore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    /** Is auth loading, is the user logged in, or is the user logged out? */
    authState,
    /** Raw auth state from SkySlope Auth. DOES NOT TAKE INTO ACCOUNT WHEN AUTH IS LOADING! */
    isAuthenticated,
    /** Function to log out the current user */
    logout,
    /**
     * Log in SSO user for a particular IDP.
     *
     * If they're already logged in to their SSO they will be brought
     * back to the current page after a few redirects.
     *
     * If they're not logged into their SSO, they will be brought to
     * the SSO login page, and then be auto-redirected back to the
     * current page.
     */
    loginSSOUser,
    /**
     * Validate that the passed string represents an IDP that
     * we currently support.
     * (this is different from the functionality of the function
     * of the same name in @skyslope/auth-config)
     */
    validateIDP,
  }
}
