import React from 'react'
import {useSetAtom} from 'jotai'

import {useRouter} from '~/hooks'
import {previousUrlAtom} from '~/state'

/**
 * This hook will detect the popstate event and save the previous url to state.
 * The popstate event does not fire when client side navigation occurs though, so
 * without further intervention the previous url will often be incorrect.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
 *
 * Having the url be incorrect is fine for the moment though, because we just need
 * to know if the user can go back via their browser history to a previous Offers
 * page.
 */
export function useSavePreviousUrl() {
  const {router} = useRouter()
  const setPreviousUrl = useSetAtom(previousUrlAtom)

  React.useEffect(() => {
    router.beforePopState((state) => {
      setPreviousUrl(state.as)
      return true
    })
  }, [router, setPreviousUrl])
}
