import React from 'react'
import Close from '@mui/icons-material/Close'
import {
  type OptionsObject,
  type SnackbarKey,
  type VariantType,
  useSnackbar as useNotistackSnackbar,
} from 'notistack'

import {ErrorCodes} from '~/utils'

export type SnackbarOptions = OptionsObject & {
  message?: string
  code?: ErrorCodes
}

export function useSnackbar(defaultOptions?: SnackbarOptions) {
  const {enqueueSnackbar, closeSnackbar} = useNotistackSnackbar()

  const SnackbarDismissAction = (key: SnackbarKey) => (
    <Close className="cursor-pointer" onClick={() => closeSnackbar(key)} />
  )

  function addSnackbar(variant: VariantType) {
    return (options: SnackbarOptions) => {
      const {
        message = "Oops! That's not supposed to happen. Please try again in a few seconds.",
        persist,
        code,
        ...restOptions
      } = {
        ...defaultOptions,
        ...options,
      } as SnackbarOptions

      const finalPersist = persist == null ? variant === 'error' : persist
      const codeSuffix = code ? ` Code: ${code}` : ''
      return enqueueSnackbar(message + codeSuffix, {
        key: code || undefined,
        variant,
        persist: finalPersist,
        preventDuplicate: variant === 'error',
        action: finalPersist ? SnackbarDismissAction : null,
        ...restOptions,
      })
    }
  }

  return {
    close: closeSnackbar,
    info: addSnackbar('info'),
    success: addSnackbar('success'),
    error: addSnackbar('error'),
  }
}

export type {SnackbarKey}
