type Value = string | string[] | number | number[] | boolean | boolean[] | null | undefined

export function queryString(queryObj: Record<string, Value>) {
  const params = new URLSearchParams()

  function addParam(key: string, value: Value) {
    if (value == null) return
    if (Array.isArray(value)) {
      value.forEach((item) => addParam(key, item))
    } else {
      params.append(key, encode(value))
    }
  }

  Object.entries(queryObj).forEach(([key, value]) => addParam(key, value))
  return params.toString()
}

export function encode(urlComponent: string | number | boolean | null | undefined) {
  if (urlComponent == null) return ''
  return encodeURIComponent(String(urlComponent).replace(/ +/g, '-'))
}
