import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'
import {linkTrrebUser} from '~/api'
import {ErrorCodes} from '~/utils'
import {useErrorReporter} from '../use-error-reporter'
import {getUserProfileKey} from '../_queries'

/**
 * Mutation to handle linking TRREB accounts.
 * This could have been just a simple promise/API call, but I felt it was cleaner
 * to use the onSuccess of the mutation to invalidate the query cache for user profile
 * @param options
 * @returns id of linked trreb account
 */
export function useLinkTrrebAccountMutation(
  options: UseMutationOptions<string, AxiosError, string> = {},
) {
  const queryClient = useQueryClient()
  const {reportError} = useErrorReporter(ErrorCodes.LinkTrrebUser)

  return useMutation(linkTrrebUser, {
    ...options,
    async onSuccess() {
      // There's no skyslope-auth-js API to force a refresh of the tokens, so we'll just force an update of all the tokens
      // Also have to delay a bit to let Okta update the user profile
      // setTimeout(() => sessionStorage.removeItem('com.skyslope.id.tokens'), 300)
      queryClient.removeQueries(getUserProfileKey)
    },
    onError(e, variables, context) {
      options?.onError?.(e, variables, context)
      reportError(e)
    },
  })
}
