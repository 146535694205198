import {useCallback, useEffect, useState} from 'react'
import {getLastIdp} from '@skyslope/auth-config'
import {useAuth} from '@skyslope/auth-react'
import {captureException} from '@sentry/react'
import {useSetAtom} from 'jotai'

import {deletePreferences} from '~/api'
import {trrebUserLinkingRedirectUrlAtom} from '~/state'
import {envSettings} from '~/utils'
import {useLinkTrrebAccountMutation} from './use-link-trreb-account'

/**
 * Forms source: https://bitbucket.org/skyslope/files-ui/src/master/src/components/SecurityDependentEffects/hooks/useTrrebMemberOnboarding.ts
 * This hook handles a TRREB user when they are logging in for the first time.
 * The if statement inside the useEffect has all the necessary conditions for
 * the user to be considered for the "linking" process.
 * @returns boolean to show a loading screen if needed
 */
export function useTrrebOnboarding() {
  const {authState, userManager} = useAuth()
  const claims = authState.idToken?.claims
  const accessClaims = authState.accessToken?.claims
  const trrebLoginId = claims?.login_id
  const claimsId = accessClaims?.delegated_user_id ?? claims?.sub

  const [hasUserBeenLinked, setHasUserBeenLinked] = useState(false)
  const setTrrebRedirectUrl = useSetAtom(trrebUserLinkingRedirectUrlAtom)
  const [showGlobalLoadingScreen, setShowGlobalLoadingScreen] = useState(false)

  const {mutateAsync: linkTrrebUser} = useLinkTrrebAccountMutation()

  const linkOktaAccount = useCallback(
    async (id: string): Promise<void> => {
      try {
        const userId = await linkTrrebUser(id)
        if (userId === claimsId) {
          setShowGlobalLoadingScreen(false)
          setHasUserBeenLinked(true)
        } else {
          // Once user is linked, save their current url and log them out of the invalid account.
          // This needs to be in localStorage because the sessionStorage redirect url
          // will get cleared out after logging out.
          setTrrebRedirectUrl(window.location.href)
          await deletePreferences(claimsId ?? '')
          await userManager?.startLogout()
          setHasUserBeenLinked(true)
        }
      } catch (e) {
        console.error('Error while linking TRREB User', e)
        captureException(e)
      }
    },
    [claimsId, linkTrrebUser, setShowGlobalLoadingScreen, userManager, setTrrebRedirectUrl],
  )

  useEffect(() => {
    // This uses localStorage, so this needs to be in the use effect
    const lastIdp = getLastIdp()
    const isTrrebUserLogin =
      lastIdp === envSettings.okta.idps.associations.trreb ||
      lastIdp === envSettings.okta.idps.associations.trreb_amp

    async function link(trrebLoginId: string) {
      await linkOktaAccount(trrebLoginId)
    }

    try {
      // We only need to go through this flow the first time a trreb user logs in
      if (
        isTrrebUserLogin &&
        !hasUserBeenLinked &&
        (claims?.email?.endsWith('@trreb.invalid') ?? true) &&
        trrebLoginId != null &&
        authState.isAuthenticated
      ) {
        setShowGlobalLoadingScreen(true)
        link(trrebLoginId)
      }
    } catch (e) {
      console.error('Error while linking TRREB User', e)
      captureException(e)
      setShowGlobalLoadingScreen(false)
    }
  }, [
    setShowGlobalLoadingScreen,
    claims,
    hasUserBeenLinked,
    linkOktaAccount,
    authState,
    userManager,
    trrebLoginId,
  ])

  return {
    shouldShowLoadingPage: showGlobalLoadingScreen,
  }
}
