import {useEffect, useState} from 'react'
import App from 'next/app'
import type {AppContext, AppProps} from 'next/app'
import Head from 'next/head'
import {useMediaQuery, useTheme} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'
import {ThemeProvider as StylesThemeProvider} from '@mui/styles'
import {QueryClient, QueryClientProvider, Hydrate, useMutation} from 'react-query'
import {enableMapSet, enablePatches} from 'immer'
import 'what-input'
import {SecurityProvider} from '@skyslope/auth-react'
import {VisibleProvider} from '@skyslope/mache'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {TouchBackend} from 'react-dnd-touch-backend'
// By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development',
// so you don't need to worry about excluding them during a production build.
import {ReactQueryDevtools} from 'react-query/devtools' // eslint-disable-line @typescript-eslint/no-unused-vars

import {theme} from '~/utils'
import {SnackbarProvider, ErrorBoundary, JustAMoment} from '~/components'
import {AuthStoreContextProvider, getUserManager, useAuthStore} from '~/auth'
import {useInitAnalytics, GoogleAnalytics} from '~/utils/analytics'
import type {HasChildren} from '~/types'
import {
  LaunchDarklyFlagsProvider,
  type OMFlags,
  useProcessInvites,
  useSaveIDPQueryParam,
  useSyncUserWithMls,
  useSavePreviousUrl,
  useTrrebOnboarding,
} from '~/hooks'

import '~/styles/reset.css'
import '~/styles/preflight.css'
import '~/styles/globals.css'
import {getOrCreateUserProfile} from '~/api'

enableMapSet()
enablePatches()

type MyAppProps = AppProps<{
  dehydratedState?: any
  flags?: OMFlags
}>

// This disables static site optimization for all pages.
// Disabling SSG is done here because SSG pages use the build time env vars,
// and can’t access the env vars that we forcibly update after the build
// runs in the server.js file.
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  return {...appProps}
}

export default function MyApp(props: MyAppProps) {
  const {Component, pageProps} = props
  useSavePreviousUrl()

  return (
    <Providers>
      <ErrorBoundary>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <GoogleAnalytics id="UA-108391665-2" />
        <Hydrate state={pageProps.dehydratedState}>
          <LaunchDarklyFlagsProvider serverFlags={pageProps.flags ?? ({} as OMFlags)}>
            <RunOncePerSession />
            <Component {...pageProps} />
          </LaunchDarklyFlagsProvider>
          {/* <ReactQueryDevtools /> */}
        </Hydrate>
      </ErrorBoundary>
    </Providers>
  )
}

const isDevMode =
  process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_REACT_QUERY_AUTO_REFRESH
const queryClient = new QueryClient({
  defaultOptions: {queries: {staleTime: isDevMode ? 0 : 5 * 60 * 1000}},
})

function Providers({children}: HasChildren) {
  return (
    <ThemeProvider theme={theme}>
      <StylesThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{
            containerAnchorOriginTopRight: 'md:pt-16',
          }}
        >
          <QueryClientProvider client={queryClient}>
            <DragAndDropProvider>
              <VisibleProvider>
                <OffersAuthProvider>{children}</OffersAuthProvider>
              </VisibleProvider>
            </DragAndDropProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </StylesThemeProvider>
    </ThemeProvider>
  )
}

function OffersAuthProvider({children}: HasChildren) {
  const isSSR = typeof window === 'undefined'
  const [renderAuth, setRenderAuth] = useState(!isSSR)

  useEffect(() => {
    if (renderAuth || isSSR) return
    setRenderAuth(true)
  }, [renderAuth, isSSR])

  if (!renderAuth) return <>{children}</>
  return (
    <SecurityProvider userManager={getUserManager()}>
      <AuthStoreContextProvider>{children}</AuthStoreContextProvider>
    </SecurityProvider>
  )
}

function DragAndDropProvider({children}: HasChildren) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {defaultMatches: false})
  const dndBackend = isMobile ? TouchBackend : HTML5Backend
  return (
    <DndProvider key={String(isMobile)} backend={dndBackend}>
      {children}
    </DndProvider>
  )
}

function RunOncePerSession() {
  const {userInfo, userId} = useAuthStore()

  useSyncUserWithMls()
  useProcessInvites()
  useSaveIDPQueryParam()
  useInitAnalytics()

  const {mutate: getOrCreateFormsUser} = useMutation(getOrCreateUserProfile, {
    onError: (e) => console.error(e),
    retry: false,
  })

  useEffect(() => {
    if (!userId) return
    getOrCreateFormsUser({
      userId,
      firstName: userInfo?.firstName ?? '',
      lastName: userInfo?.lastName ?? '',
      email: userInfo?.email ?? '',
    })
  }, [getOrCreateFormsUser, userId, userInfo?.email, userInfo?.firstName, userInfo?.lastName])

  const {shouldShowLoadingPage} = useTrrebOnboarding()
  return shouldShowLoadingPage ? <JustAMoment /> : null
}
