// Getting a named import error for UserManager
// eslint-disable-next-line import/named
import {TokenDomain, createUserManager, Environment, UserManager} from '@skyslope/auth-js'
import {config, ENV} from '~/utils/env-constants'

let userManager: UserManager

function create(): UserManager {
  userManager = createUserManager({
    environment: ENV as unknown as Environment,
    tokenDomain: TokenDomain.Forms,
    providers: {
      okta: {
        clientId: config!.okta!.clientId,
        redirectPath: '/auth/callback',
        postLogoutRedirectPath: '/logout',
        scopes: [
          'openid',
          'email',
          'profile',
          'com.skyslope.prime.subscriber',
          'com.skyslope.groups',
          'com.skyslope.forms.api',
        ],
        devMode: false, // Disable annoying console logs from okta-auth-js and skyslope-auth-js
      },
      primeAuth: {
        clientId: config!.okta!.primeIdp,
        redirectPath: '/auth/callback/prime',
        postLogoutRedirectPath: '',
      },
    },
    // Don't need to implement this yet until we have our own login screen
    // restoreOriginalUri(oktaAuth: any, originalUri: string) {
    //   return Promise.resolve()
    // },
  })
  return userManager
}

//singleton
function getUserManager(): UserManager {
  if (typeof window === 'undefined') {
    return {} as UserManager
  }
  if (userManager) return userManager
  return create()
}

export {userManager, getUserManager}
