import Script from 'next/script'
import {useFeatureFlags} from '~/hooks'
import {config} from '../env-constants'

/**
 * Initialize Microsoft Clarity
 */
export function MicrosoftClarity() {
  const flags = useFeatureFlags()
  const enabled = flags.isMicrosoftClarityEnabled
  if (!enabled) return null
  if (process.env.NODE_ENV === 'development') return null

  return (
    <Script id="microsoft-clarity">
      {`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${config.microsoftClarityId}");
        `}
    </Script>
  )
}

export function clarityIdentify(props: {email: string}) {
  if (window.clarity) {
    window.clarity('set', 'email', props.email)
  }
}
