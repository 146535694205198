import {useEffect} from 'react'
import {useAtom} from 'jotai'
import {useAuth} from '@skyslope/auth-react'

import {shouldProcessInvitesAtom} from '~/state'
import {processPendingInvites} from '~/api/team/process-pending-invites'
import {useAuthHelpers} from '../auth'

/**
 * This hook checks to see if we should process any pending team access invites (once per session)
 * We do not use the result from this api call, so we can ignore any errors
 */
export function useProcessInvites() {
  const {authState: loggedInState} = useAuthHelpers()
  const {authState} = useAuth()
  const [shouldProcessInvites, setShouldProcessInvites] = useAtom(shouldProcessInvitesAtom)

  const claims = authState.idToken?.claims
  const email = claims?.email

  useEffect(() => {
    if (loggedInState === 'logged-in' && shouldProcessInvites && email) {
      processInvites(email)
      setShouldProcessInvites(false)
    }

    async function processInvites(email: string) {
      try {
        await processPendingInvites(email)
      } catch (e: any) {
        console.info(e)
      }
    }
  }, [loggedInState, shouldProcessInvites, setShouldProcessInvites, email])
}
