import {offersApi} from '~/auth/api-clients'

/**
 * Fetch to see if bearer has roleId
 */
export async function checkUserRole(isAuthenticated: boolean, roleId: string): Promise<boolean> {
  if (!isAuthenticated) return false
  const res = await offersApi.get(`/auth/user-role/${roleId}`, {
    validateStatus: (status) => status === 200 || status === 404 || status === 401,
  })
  return res.data === 'ok'
}
