import {useEffect} from 'react'
import {useLDClient} from 'launchdarkly-react-client-sdk'

import {useAuthStore} from '~/auth'
import {chameleonInit, chameleonIdentify} from './chameleon'
import {heapInit, heapIdentify} from './heap'
import {sentryInit} from './sentry'
import {clarityIdentify} from './microsoft-clarity'

chameleonInit()
heapInit()
sentryInit()

/**
 * Importing this file will initialize all analytics providers.
 * Running this hook will identify this user wil all analytics providers once the user is logged in.
 */
export function useInitAnalytics() {
  const {userInfo, primeUserInfo, userId} = useAuthStore()
  const ldClient = useLDClient()

  const firstName = userInfo.firstName ?? ''
  const lastName = userInfo.lastName ?? ''
  const email = userInfo.email ?? ''

  useEffect(() => {
    if (!userId) return

    chameleonIdentify({userId, firstName, lastName, email})
    clarityIdentify({email})
    heapIdentify({
      userId,
      email,
      SubscriberId: primeUserInfo?.SubscriberId,
      UserType: primeUserInfo?.UserType,
    })

    if (ldClient) {
      ldClient?.identify({key: email.toLowerCase(), firstName, lastName, email})
    }
  }, [
    email,
    firstName,
    lastName,
    userId,
    ldClient,
    primeUserInfo?.SubscriberId,
    primeUserInfo?.UserType,
  ])
}
