import {useState} from 'react'
import {Tooltip as MuiTooltip, type TooltipProps as MuiTooltipProps} from '@mui/material'
import {type StateSetter} from '@skyslope/mache'

import {cs} from '~/utils'

export type TooltipProps = Omit<MuiTooltipProps, 'open' | 'title'> & {
  isOpen?: boolean
  setIsOpen?: StateSetter<boolean>
  title: MuiTooltipProps['title'] | null
}

/**
 * A stylized version of MUI's tooltip
 */
export function Tooltip(props: TooltipProps) {
  const {
    children,
    className,
    classes,
    isOpen: externalIsOpen,
    setIsOpen: externalSetIsOpen,
    onOpen,
    onClose,
    title,
    ...rest
  } = props

  const [internalIsOpen, internalSetIsOpen] = useState(false)
  const isOpen = externalIsOpen ?? internalIsOpen
  const setIsOpen = externalSetIsOpen ?? internalSetIsOpen

  if (!title) return children

  return (
    <MuiTooltip
      arrow
      {...rest}
      open={isOpen}
      onOpen={(e) => {
        setIsOpen(true)
        onOpen?.(e)
      }}
      onClose={(e) => {
        setIsOpen(false)
        onClose?.(e)
      }}
      className={cs('Tooltip', className)}
      classes={{
        ...classes,
        popper: cs('drop-shadow-lg', classes?.popper),
        arrow: cs('text-white before:border before:border-grey-200', classes?.arrow),
        tooltip: cs(
          'bg-white drop-shadow-lg text-grey-800 p-2 border border-grey-200 text-xs rounded-lg',
          classes?.tooltip,
        ),
      }}
      title={title}
    >
      {children}
    </MuiTooltip>
  )
}
