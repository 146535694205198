import {useEffect} from 'react'

import {useRouter} from '../use-router'
import {usePreferredIDP} from './use-preferred-idp'

/**
 * If the `idp` query param appears in the URL, make sure that we
 * save that to local storage.
 *
 * This ensures that the user gets the correct Identity Provider
 * experience on other urls that don't have that query param as
 * well.
 */
export function useSaveIDPQueryParam() {
  const {query} = useRouter()
  const {setPreferredIDP} = usePreferredIDP()

  useEffect(() => {
    if (query.idp) setPreferredIDP(query.idp)
  }, [query.idp, setPreferredIDP])
}
