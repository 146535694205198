import React from 'react'
import {useFlags as useClientFlags} from 'launchdarkly-react-client-sdk'

import {OMFlags} from './types'
import {useServerFlags} from './launch-darkly-flags-provider'

// Add your debug flags here
const debugFlags: Partial<OMFlags> = {}

export function useFeatureFlags(): OMFlags {
  const serverFlags = useServerFlags() as OMFlags
  const clientFlags = useClientFlags() as OMFlags

  return React.useMemo(() => {
    const isDevelopment = process.env.NODE_ENV === 'development'
    const _debugFlags = isDevelopment ? debugFlags : {}

    return {...serverFlags, ...clientFlags, ..._debugFlags} as OMFlags
  }, [serverFlags, clientFlags])
}
