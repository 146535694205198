// The only reason these start at 800 is so that they can't be
// confused with HTTP status codes.

/**
 * Define error codes to be shown to the user.  Defining a unique error code for
 * each possible error that can be reported to the user makes it easier for
 * devs to diagnose problems and understand exactly where an error is happening
 * in the code.
 *
 * The only reason these start at 800 is so that they can't be confused with
 * HTTP status codes.
 */
export enum ErrorCodes {
  UpdateListing = 802,
  GetListing = 803,
  GetPublicListing = 804,
  CreateDraftOffer = 805,
  GetDraftOfferInfo = 806,
  UpdateDraftOffer = 807,
  UploadOfferDocument = 808,
  RemoveUploadedDocument = 809,
  SubmitOffer = 810,
  GetOfferSubmissionLink = 811,
  GetThumbnail = 812,
  RemoveAdditionalDocuments = 813,
  GetPresignedUrl = 814,
  GetOrCreateListing = 815,
  SendEmail = 816,
  CreateShareLink = 817,
  GetSharedListing = 818,
  UpdateSubmittedOffer = 819,
  UpdateOfferNote = 820,
  ResendShareLink = 821,
  GetListingsForAgent = 822,
  CreateListing = 823,
  UpdateOfferFavorite = 824,
  GetListingActivity = 825,
  ArchiveListing = 826,
  ShareAccountAccess = 827,
  GetTeam = 828,
  RemoveTeamAccess = 829,
  GetFormsFilesForAgent = 830,
  GetUserProfile = 831,
  UpdateUserProfile = 832,
  GetPreferences = 833,
  UpdatePreferences = 834,
  GetTokens = 835,
  GetMlsOfferLink = 836,
  GetOfferDocuments = 837,
  EditListing = 838,
  CheckUserRole = 839,
  RequestTeamAccess = 840,
  UpdateOfferDocuments = 841,
  LinkTrrebUser = 842,
  SendBuyerAgentsEmails = 843,
  HandleEnvelope = 844,
  SendLeaseOfferAcceptance = 845,
  SendOfferDocsEmail = 846,
  GetCounterForms = 847,
  AddFormsAndFill = 848,
  GetListingViews = 849,
  UpdateOfferStatus = 850,
  GetBrokerStats = 851,
  GetBrokerGroups = 852,
  GetBuyerListing = 856,
  GetBuyerListingRefsForAgent = 857,
  GetBuyerListingActivity = 858,
  ArchiveBuyerListing = 856,
  UpdateBuyerListingClientInfo = 857,
  UpdateOfferDocumentOrder = 858,
  GetSignedDocuments = 859,
  PrefillOffer = 860,
  GetFormsFile = 861,
}
