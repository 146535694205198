import {type SupportedIDPs, idpReferrerUrls} from '@skyslope/offers-shared'
import {envSettings} from '~/utils'

export function getIdpFromReferrer(referrer = '') {
  if (!referrer) return validateIDP(null)

  const matchedReferrerUrlEntry = Object.entries(idpReferrerUrls).find(([, knownReferrerUrls]) => {
    return knownReferrerUrls.find((url) => url && referrer.includes(url))
  })

  // We know that idp here is valid (if it exists) because it came from
  // the keys of idpReferrerUrls
  const idp = matchedReferrerUrlEntry?.[0] as SupportedIDPs
  if (idp) return validateIDP(idp)

  if (referrer.includes('stellar')) return validateIDP('stellar')

  const match = referrer.match(/idp[=/](.+)/)
  return validateIDP(match?.[1])
}

/**
 * Validate that the passed IdP string can be transformed
 * into a valid IdP.
 * @param idp
 * @returns An object that contains the transformed, valid IdP string,
 * and the GUID-ish key that represents this IdP in the SkySlope Auth
 * User Manager.
 */
export function validateIDP(idp: SupportedIDPs | string | null | undefined): {
  key: string | null
  idp: SupportedIDPs | null
} {
  if (!idp) return {key: null, idp: null}

  const _idp = idp.toLowerCase() as SupportedIDPs

  if (_idp === 'brightmls') return {key: envSettings.okta.idps.associations.bright, idp: _idp}

  const idpKey = envSettings.okta?.idps?.associations?.[_idp]
  const isIdpValid = !!idpKey

  return isIdpValid ? {key: idpKey, idp: _idp} : {key: null, idp: null}
}
