import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import {useAuth} from '@skyslope/auth-react'
import {buildAuthStoreFromAuthState} from './okta'

export type AuthStore = {
  accountAction: null | 'none' | 'legacy' | 'register'
  loggedIn: boolean
  userId: string | null
  userInfo: {
    firstName: string
    lastName: string
    email: string
    phoneNumber?: string
  }
  primeUserInfo?: {
    SubscriberId?: number
    UserType?: string
  }
  formsUserCheckComplete: boolean
}

const defaultStore: AuthStoreWithHelpers = {
  accountAction: null,
  loggedIn: false,
  userId: null,
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
  },
  update: () => null,
  reset: () => null,
  formsUserCheckComplete: false,
}

interface AuthStoreWithHelpers extends AuthStore {
  update: (updated: Partial<AuthStore>) => void
  reset: () => void
}

export const AuthStoreContextProvider = ({children}: {children: ReactNode}) => {
  const [store, setStore] = useState<AuthStore>(defaultStore)
  const [ready, setReady] = useState(false)
  const {authState} = useAuth()

  const update = (updated: Partial<AuthStore>) => {
    setStore({...store, ...updated})
  }

  const reset = () => {
    setStore(defaultStore)
  }

  useEffect(() => {
    buildAuthStoreFromAuthState().then((authStore) => {
      if (authStore.loggedIn) {
        setStore(authStore)
      }
      setReady(true)
    })
  }, [authState])

  return (
    <AuthStoreContext.Provider value={{...store, update, reset}}>
      {ready && children}
    </AuthStoreContext.Provider>
  )
}
export const AuthStoreContext = createContext<AuthStoreWithHelpers>(defaultStore)
export const useAuthStore = () => useContext(AuthStoreContext)
