import {cs} from '~/utils'
import type {HtmlElProps} from '~/types'

import LogoImage from 'public/static/images/ss-apps/om-logo.svg'

export type OffersLogoProps = HtmlElProps & {}

/**
 * An image of the Offer Management logo
 */
export function OffersLogo(props: OffersLogoProps) {
  return <LogoImage {...props} height={32} className={cs('OffersLogo', 'ml-5', props.className)} />
}
