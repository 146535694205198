/** Initialize Chameleon. See trychameleon.com */

export function chameleonInit() {
  if (typeof window === 'undefined') return
  const accountToken = 'SIMVvFC0cW5aRmXMWnBWuSrsLp6oY3utpm8ZD5ciik5ByF-1H74NM-Bgha6VrqZM0nduVB'
  const methods = 'identify alias track clear set show on off custom help _data'.split(' ')
  window.chmln = window.chmln ?? {}
  if (!window.chmln.root) {
    window.chmln.accountToken = accountToken
    window.chmln.location = window.location.href
    window.chmln.now = new Date()
    methods.forEach((m) => {
      window.chmln[m + '_a'] = []
      window.chmln[m] = (...args: any) => {
        window.chmln[m + '_a'].push(args)
      }
    })
    const scriptEl = document.createElement('script')
    scriptEl.src = 'https://fast.trychameleon.com/messo/' + accountToken + '/messo.min.js'
    scriptEl.async = true
    document.head.appendChild(scriptEl)
  }
}

export type ChameleonProps = {
  userId: string
  firstName: string
  lastName: string
  email: string
}

export function chameleonIdentify(props: ChameleonProps) {
  window.chmln.identify(props.userId, {
    email: props.email,
    firstName: props.firstName,
    lastName: props.lastName,
  })
}
