import {Menu, type MenuProps} from '@mui/material'

import {cs} from '~/utils'

export type HeaderMenuProps = Omit<MenuProps, 'anchorOrigin' | 'transformOrigin'> & {
  /** Control how the menu aligns with its anchor. Default is right */
  horizontalAlignment?: 'left' | 'center' | 'right'
}

/**
 * A MUI Menu styles for the OM header bar
 */
export function HeaderMenu(props: HeaderMenuProps) {
  const {className, classes, horizontalAlignment = 'right', ...rest} = props

  return (
    <Menu
      {...rest}
      anchorOrigin={{vertical: 'bottom', horizontal: horizontalAlignment}}
      transformOrigin={{vertical: 'top', horizontal: horizontalAlignment}}
      classes={{paper: cs('min-w-[200px]', classes?.paper)}}
      className={cs('HeaderMenu', className)}
      disableScrollLock
    />
  )
}
