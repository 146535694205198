import {useRouter as useNextRouter} from 'next/router'
import {useRoutes, type AppQuery} from './use-routes'

/**
 * Get the Next router, Offers route functions, and a strongly typed
 * object representing the current url query.
 */
export function useRouter() {
  const router = useNextRouter()
  const query = (router?.query ?? {}) as unknown as AppQuery

  const {routes} = useRoutes()
  return {router, routes, query}
}
