import {useMemo, useCallback} from 'react'
import {useRouter} from 'next/router'
import {type SupportedIDPs, type MLSMarket} from '@skyslope/offers-shared'

import {config} from '~/utils'
import {OfferSaleTerms} from '~/types'
import {BrokerStatsTimePeriodFilter} from '~/page-components/broker-stats/time-period-filter-bar'
import {queryString, encode as e} from './query-string'

/** Route parameters of the app (contained directly in the url path between "/"s) */
export type AppRouteParams = {
  /** Code generated to create buyer offer submission links */
  linkId: string
  /** The property address.  This param is purely visual (in the URL) and is non-functional */
  propertyStub?: string
  /** The ID of the offer being submitted or viewed */
  offerId?: string
  /** The file ID of the listing being viewed */
  fileId?: string
  /** The shareId that identifies the share that the Listing Agent sent to Seller to review offers */
  shareId?: string
  /** ID generated from encoding the seller info */
  clientId?: string
  /** ID of the buyer agent who owns the buyer listing */
  buyerAgentOktaId: string
}

/** Query parameters of the app (contained after "?" in the url) */
export type AppQueryParams = {
  /** The url that the back button should navigate back to */
  backTo?: string
  /** The active section of the Buyer Info page */
  section: 'buyer' | 'terms' | 'agent'
  /** Direct link to submit offer or from external click.  prepop=false does not prepop buyer agent from claims */
  prepop: boolean
  /** Array of offer ids to compare on the comparison page */
  /** Array of offer ids to compare on the offer compare page */
  offer?: string[]
  /** Array of offer terms to be displayed on the mobile offer compare page */
  term?: Array<keyof OfferSaleTerms>
  /** Used on the shared (with sellers) offers page to indicate which set of offers to display */
  shareViewType?: 'all' | 'saved' | 'shared' | 'declined'
  /** Used on the listings dash to indicate if active or archived listings should be shown to the user */
  filter?: 'active' | 'archived'
  /** Used on the offer details page to control which tab is shown */
  detailTab?: 'terms' | 'contacts' | 'documents' | 'notes'
  /** Used on the account settings page to control which tab is shown */
  accountTab?:
    | 'personal'
    | 'professional'
    | 'instructions'
    | 'security'
    | 'notifications'
    | 'integrations'
  /** Used to determine which SSO provider the user should be directed to */
  idp?: SupportedIDPs
  /** Market + Listing ID is a unique identifier for a listing - used on MLS submit offer page  */
  market?: MLSMarket
  /** MLS Listing ID - used on MLS submit offer page */
  mlsListing: string
  /** The Forms representation type to forward - used on MLS submit offer page */
  representationType: 'buyer' | 'seller'
  /**
   * After prefilling an offer with information from Forms, they are redirected to the submit offer info page.
   * We need this query param to know how to redirect the user back to Forms
   */
  buyerFileId?: string
  /** The ID of the Forms envelope containing the signed document to prefill an offer with */
  envelopeId?: string
  /** The ID of the signed document on the envelope to prefill an offer with */
  documentId?: string
  /** The time period filter on the broker stats page */
  months?: BrokerStatsTimePeriodFilter
}

export type AppQuery = AppRouteParams & AppQueryParams
type GenerateRoute = (p: AppQuery) => string

/**
 * This hook gives easy access to the apps routes without having
 * to worry about dealing with maintaining query parameters.
 */
export function useRoutes() {
  const route = useRouteGenerator()

  // prettier-ignore
  const routes = useMemo(
    () => ({
      buyer: {
        chooseFlow: route((p) => `/submit/${p.linkId}/${e(p.propertyStub)}`,
          ['prepop', 'buyerFileId', 'envelopeId', 'documentId']
        ),
        upload: route((p) => `/submit/${p.linkId}/${e(p.propertyStub)}/${p.offerId}/upload`),
        info: route(
          (p) => `/submit/${p.linkId}/${e(p.propertyStub)}/${p.offerId}/info`,
          ['section', 'buyerFileId'],
        ),
        improve: route((p) => `/submit/${p.linkId}/${e(p.propertyStub)}/${p.offerId}/improve`),
        review: route(
          (p) => `/submit/${p.linkId}/${e(p.propertyStub)}/${p.offerId}/review`,
          ['buyerFileId'],
        ),
        done: route(
          (p) => `/submit/${p.linkId}/${e(p.propertyStub)}/${p.offerId}/done`,
          ['buyerFileId'],
        ),
        openOffers: route((p) => `/open/${p.linkId}/offer/${p.offerId}`),
        offersDash: route((p) => `/client/${p.clientId}/agent/${p.buyerAgentOktaId}`),
        detailedOffers: route((p) => `/client/${p.clientId}/agent/${p.buyerAgentOktaId}/detailed`),
        editClientInfo: route((p) => `/client/${p.clientId}/agent/${p.buyerAgentOktaId}/edit`),
        offerDetails: route((p) => `/client/${p.clientId}/agent/${p.buyerAgentOktaId}/offer/${p.offerId}`, ['detailTab']),
        activityLog: route((p) => `/client/${p.clientId}/agent/${p.buyerAgentOktaId}/log`),
      },
      mls: {
        submitOffer: route(
          () => `/submit/offer`,
          ['idp', 'market', 'mlsListing', 'representationType'],
        ),
      },
      seller: {
        offersDash: route((p) => `/file/${p.fileId}`),
        detailedOffers: route((p) => `/file/${p.fileId}/detailed`),
        compare: route((p) => `/file/${p.fileId}/compare`, ['offer', 'term']),
        reviewShare: route((p) => `/file/${p.fileId}/view/${p.shareId}`),
        reviewOther: route((p) => `/file/${p.fileId}/view/${p.shareId}`, ['shareViewType']),
        activityLog: route((p) => `/file/${p.fileId}/log`),
        listingDash: route(() => `/listings`, ['filter']),
        createListing: route(() => `/listings/create`),
        editListing: route((p) => `/listings/${p.fileId}/edit`),
        offerDetails: route((p) => `/file/${p.fileId}/offer/${p.offerId}`, ['detailTab']),
        myTeam: route(() => `/team`),
        addTeamMember: route(() => `/team/add`),
        account: route(() => `/account`, ['accountTab']),
        buyerAgentActivity: route((p) => `/file/${p.fileId}/stats`),
        brokerStats: route(() => `/broker-stats`, ['months']),
      },
      external: {
        formsFile: route((p) =>
          p.fileId ? `${config.formsUrl}/file-details/${p.fileId}/documents` : config.formsUrl,
        ),
        formsAccount: route(() => `${config.formsUrl}/settings/profile`),
        formsCreateOffer: route(
          () => `${config.formsUrl}/create`,
          ['idp', 'market', 'mlsListing', 'representationType'],
          {forwardQuery: true},
        ), // e.g. https://forms.skyslope.com/create?&idp=TRREB&market=on_treb&mlsListing=X5950271&representationType=buyer
        brokerageAdmin: route(
          () => `${config.adminUrl}?returnUrl=${encodeURIComponent(window.location.href)}`,
        ),
      },
    }),
    [route],
  )

  return {routes}
}

function useRouteGenerator() {
  const router = useRouter()
  const query = router?.query as unknown as AppQuery

  /**
   * @param genRoute - A function that takes an AppQuery obj & returns the string for a route
   * @param queryParamNames - An array of keys of AppQueryParams to indicate which query params
   * should be added to this route.
   */
  const route = useCallback(
    (
      genRoute: GenerateRoute,
      individualQueryParamNames?: Array<keyof AppQueryParams> | null,
      args?: {forwardQuery?: boolean},
    ) =>
      (
        routeParams?: Partial<AppQuery>,
        innerArgs?: {forwardQuery?: boolean; backToHere?: boolean},
      ) => {
        const allRouteAndQueryParams = {...query, ...routeParams}
        const forwardQueryParams = args?.forwardQuery || innerArgs?.forwardQuery
        const backToHere = innerArgs?.backToHere
        let path = genRoute(allRouteAndQueryParams)

        if (individualQueryParamNames || forwardQueryParams) {
          let queryParams: Partial<AppQueryParams> = {}

          if (forwardQueryParams) {
            const urlSearchParams = new URLSearchParams(window.location.search)
            queryParams = Object.fromEntries(urlSearchParams.entries())
          }

          if (individualQueryParamNames) {
            individualQueryParamNames.reduce((accum, queryParamName) => {
              // TS can't determine that the correct value from AppQueryParams is
              // being applied to its own key here
              // @ts-ignore
              accum[queryParamName] = allRouteAndQueryParams[queryParamName]
              return accum
            }, queryParams)
          }

          const qs = queryString(queryParams)
          if (qs) path += '?' + qs
        }

        if (backToHere) {
          const separator = path.includes('?') ? '&' : '?'
          path += `${separator}backTo=${window.location.pathname}${window.location.search}`
        }

        // When data has not yet loaded, we can generate invalid urls that have
        // two forward slashes next to each other. This url is invalid, UNLESS
        // the two forward slashes are part of the protocol (e.g. "http://")
        //
        // Note: This "negative lookbehind" regex technique is not supported
        // on some mobile browsers (it throws an error) -- in that case the invalid
        // url will be rendered until the data loads. Try-catch doesn't seem to
        // be able to deal with this type of error?
        //
        // try {
        //   const isPathInvalid = !!path.match(/(?<!:)\/\//)
        //   return isPathInvalid ? '' : path
        // } catch (e) {
        //   return path
        // }
        return path
      },
    [query],
  )

  return route
}
