import {type UserPreferences} from '@skyslope/offers-shared'

import {offersApi} from '~/auth/api-clients'

export type GetPreferencesResult = UserPreferences

/**
 * Fetch user preferences based on user Id
 */
export async function getPreferences(userId: string): Promise<GetPreferencesResult> {
  const res = await offersApi.get(`/preferences/${userId}`)
  return res.data
}
