import {UserManager} from '@skyslope/auth-js/dist/types/skyslope-auth-js'
import {useInterval, useReRender} from '@skyslope/mache'

import {getUserManager} from '~/auth'

/**
 * Monitor for when Okta is actively checking for tokens.  We know
 * that once Okta is finished checking for tokens that the value of
 * useAuth().isAuthenticated is valid.
 */
export function useMonitorOkta() {
  const reRender = useReRender()

  const userManager = getUserManager()
  const isOktaCheckingForToken = getIsOktaCheckingForToken(userManager)

  // The `tokenQueue.running` property that powers `isOktaCheckingForToken`
  // will not trigger a re-render when it changes. So if the app does
  // not happen to re-render for other reasons, a change in `isOktaCheckingForToken`
  // would not be detected.
  // This interval actively monitors isOktaCheckingForToken while it's
  // true waiting for it to turn false.
  useInterval(
    () => {
      if (!isOktaCheckingForToken) return
      const recheck = getIsOktaCheckingForToken(userManager)
      if (recheck !== isOktaCheckingForToken) reRender()
    },
    isOktaCheckingForToken ? 50 : null,
  )

  return {isOktaCheckingForToken}
}

function getIsOktaCheckingForToken(userManager: UserManager) {
  const tokenQueue = userManager?._oktaAuth?.oktaAuth?._tokenQueue
  const isOktaCheckingForToken = !tokenQueue || tokenQueue.running !== false
  return isOktaCheckingForToken
}
