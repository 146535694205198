import type {ReactElement} from 'react'
import Head from 'next/head'

import {SkySlopeAddress} from '@skyslope/utils'
import {GoBackHeader, Gradient, HeaderUserSettings} from '~/components'

import type {HtmlElProps, Listing} from '~/types'
import {cs} from '~/utils'

export type GradientLayoutProps = HtmlElProps & {
  /** Address to display as the default `headerTitle` */
  listing?: Listing
  /** Text to display at the top of the page.  Default is listing address (if listing is passed) */
  headerTitle?: string
  /** The content to show in the top left corner of the layout */
  topLeft: ReactElement
  /** If true, a vertical divider will be placed between the top left content and the page title */
  showTopLeftSeparator?: boolean
  classes?: {
    root?: string
    childWrapper?: string
  }
  documentTitle?: string
}

/**
 * With a white header (with OM logo in the top left), and the blue gradient underneath the header bar
 */
export function GradientLayout(props: GradientLayoutProps) {
  const {
    listing,
    children,
    className,
    classes,
    headerTitle,
    topLeft,
    showTopLeftSeparator,
    documentTitle = 'SkySlope - Offers',
    ...rest
  } = props

  return (
    // This border prevents margin-collapse with the root element
    <div {...rest} className={cs('GradientLayout border-t', className, classes?.root)}>
      <Head>
        <title>{documentTitle}</title>
      </Head>
      <GoBackHeader
        title={headerTitle || SkySlopeAddress.formatLong(listing?.property)}
        backBtn={topLeft}
        classes={{
          backBtnSeparator: showTopLeftSeparator ? undefined : 'border-l-0',
        }}
        rightSideContent={<HeaderUserSettings />}
      />
      <Gradient />
      <div
        className={cs(
          'GradientLayout.childWrapper mx-auto -mt-48 max-w-screen-xl',
          classes?.childWrapper,
        )}
      >
        {children}
      </div>
    </div>
  )
}
