import {useQuery, type UseQueryOptions} from 'react-query'
import {type AxiosError} from 'axios'

import {getPreferences, type GetPreferencesResult} from '~/api'
import {ErrorCodes} from '~/utils'
import {useAuthStore} from '~/auth'
import {useErrorReporter} from '../use-error-reporter'
import {useAuthState} from '../auth'

export const getPreferencesKey = 'use-get-preferences'

/**
 * This query fetches user preferences for the current logged in user
 */
export function useGetPreferences(
  options: UseQueryOptions<GetPreferencesResult, AxiosError> & {snackbar?: boolean} = {},
) {
  const {reportError} = useErrorReporter(ErrorCodes.GetPreferences)
  const {authState} = useAuthState()
  const {userId} = useAuthStore()
  const {queryKey, queryFn} = getPreferencesQuery(userId ?? '')

  return useQuery<GetPreferencesResult, AxiosError>(queryKey, queryFn, {
    ...options,
    enabled: !!userId && authState === 'logged-in',
    onError(e) {
      options?.onError?.(e)
      reportError(e, {snackbar: options.snackbar ?? true})
    },
  })
}

export function getPreferencesQuery(userId: string) {
  return {
    queryKey: [getPreferencesKey, userId],
    queryFn: () => getPreferences(userId),
  }
}
