import {ReactNode} from 'react'
import Image from 'next/future/image'

export type JustAMomentProps = {
  titleText?: string | ReactNode
  bodyText?: string | ReactNode
}

/**
 * Static loading component for handling page "hiding" situations such as for auth, etc.
 */
export function JustAMoment(props: JustAMomentProps) {
  const {titleText = 'Loading...', bodyText = "Please wait, it'll just be a moment."} = props

  return (
    <div className="flex h-screen flex-col items-center justify-center px-8">
      <Image src="/static/images/look-paper.svg" width={367} height={384} alt="" />
      <div className="flex flex-col justify-center text-center">
        <h4 className="my-8 text-3xl font-bold">{titleText}</h4>
        <h6>{bodyText}</h6>
      </div>
    </div>
  )
}
