import {useCallback} from 'react'
import {getLastIdp, setLastIdp} from '@skyslope/auth-config'
import {getIsSSR} from '@skyslope/utils'
import {type SupportedIDPs} from '@skyslope/offers-shared'

import {validateIDP} from '~/auth'
import {envSettings} from '~/utils'

export function usePreferredIDP() {
  const preferredIDP = getUserIdp()

  const setPreferredIDP = useCallback((idp: SupportedIDPs | null) => {
    const {key} = validateIDP(idp)
    if (key) setLastIdp(key)
  }, [])

  return {preferredIDP, setPreferredIDP}
}

function getUserIdp(): SupportedIDPs | null {
  const isSSR = getIsSSR()
  if (isSSR) return null

  const idps = envSettings.okta?.idps
  if (!idps?.associations) return null

  switch (getLastIdp()) {
    case idps.associations.stellar:
      return 'stellar'
    case idps.associations.trreb:
      return 'trreb_amp'
    case idps.associations.trreb_amp:
      return 'trreb_amp'
    case idps.associations.recolorado:
      return 'recolorado'
    case idps.associations.realmls:
      return 'realmls'
    case idps.associations.bright:
      return 'brightmls'
    default:
      return null
  }
}
