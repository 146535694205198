import {config} from '~/utils'
import {createAxiosInstance} from './create-axios-instance'
import {getExistingToken, getTokens, getAccountsApiToken} from './okta'

/**
 * Axios client for calling Form API with bearer token
 */
export const formsApi = createAxiosInstance({
  baseUrl: config.formsApi,
  async getAsyncHeaders() {
    const formsToken = await getExistingToken()
    const accountsApiToken = await getAccountsApiToken()
    return {
      Authorization: `Bearer ${formsToken}`,
      'x-accounts-authorization': accountsApiToken,
    }
  },
})

/**
 * Axios client for calling Offer Management API with bearer token (shared with Forms)
 */
export const offersApi = createAxiosInstance({
  baseUrl: config.apiBaseUrl,
  async getAsyncHeaders() {
    const formsToken = await getExistingToken()
    const accountsApiToken = await getAccountsApiToken()
    return {
      Authorization: `Bearer ${formsToken}`,
      'x-accounts-authorization': accountsApiToken,
    }
  },
})

/**
 * Axios client for calling Offer Management API with Forms token and DS3 Token
 */
export const offersApiWithDS3 = createAxiosInstance({
  baseUrl: config.apiBaseUrl,
  async getAsyncHeaders() {
    const {formsToken, ds3Token, accountsApiToken} = await getTokens()
    return {
      Authorization: `Bearer ${formsToken}`,
      'x-ds3-authorization': ds3Token!,
      'x-accounts-authorization': accountsApiToken!,
    }
  },
})

/**
 * Axios client for calling Offer Management API with bearer token (shared with Forms)
 */
export const accountsApi = createAxiosInstance({
  baseUrl: config.accountsUrl,
  async getAsyncHeaders() {
    const formsToken = await getExistingToken()
    const accountsApiToken = await getAccountsApiToken()
    return {
      Authorization: `Bearer ${formsToken}`,
      'x-accounts-authorization': accountsApiToken,
    }
  },
})
