import {cs} from '~/utils'
import type {HtmlElProps} from '~/types'

export type GradientProps = HtmlElProps & {
  /**
   * Cause the gradient to scroll underneath the header as the page scrolls.
   * (default: true)
   */
  scrollGradient?: boolean
}

/**
 * A pretty blue gradient shown at the top of several pages.
 * On large screens the gradient by default uses sticky positioning.
 */
export function Gradient(props: GradientProps) {
  const {className, scrollGradient = true, ...rest} = props

  return (
    <div
      {...rest}
      className={cs(
        'Gradient',
        'mesh-bg h-48 w-full md:-mb-48 lg:sticky lg:-top-3 lg:z-10 lg:mb-0',
        scrollGradient && 'lg:mt-[71px]',
        className,
      )}
    />
  )
}
