import React from 'react'
import {LDContext, LDProvider} from 'launchdarkly-react-client-sdk'

import type {HasChildren} from '~/types'
import {config} from '~/utils'
import {useAuthStore} from '~/auth'
import type {OMFlags} from './types'

const ldServerFlagsContext = React.createContext<OMFlags>({} as any)

/**
 * Provide launch darkly flags both from the server and the client
 * to the rest of the app.
 *
 * Note that this provider MUST BE A CHILD OF OffersAuthProvider
 * because it makes use of the auth profile in order to identify
 * the specific user to launch darkly.
 */
export function LaunchDarklyFlagsProvider(props: {serverFlags: OMFlags} & HasChildren) {
  const {serverFlags, children} = props
  const {userInfo} = useAuthStore()

  const ldContext: LDContext = React.useMemo(
    () => ({
      kind: 'user',
      key: userInfo.email,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    }),
    [userInfo],
  )

  return (
    <LDProvider clientSideID={config.launchDarklyId} context={ldContext}>
      <ldServerFlagsContext.Provider value={serverFlags}>{children}</ldServerFlagsContext.Provider>
    </LDProvider>
  )
}

export function useServerFlags(): OMFlags {
  const serverFlags = React.useContext(ldServerFlagsContext)
  // Because these flags are determined on the server, once they're on
  // the client, they can never change.  This memo ensures that the
  // context object changing (but containing identical values) doesn't
  // cause a re-render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => serverFlags || {}, [])
}
