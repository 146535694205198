import {format as utilsFormat, type Nullable, pluralize} from '@skyslope/utils'

import {type OfferSaleTerms} from '~/types'

export const format = {
  ...utilsFormat,
  names(people: Array<{firstName?: Nullable<string>; lastName?: Nullable<string>}> | undefined) {
    return people?.map((buyer) => format.name(buyer)).join(', ') ?? ''
  },
  daysAfter(days: Nullable<number | string>) {
    if (!days) return undefined
    return `${days} ${pluralize('day', days)} after acceptance`
  },
  financeMethod(financeMethod: Nullable<OfferSaleTerms['financeMethod']>) {
    // prettier-ignore
    switch (financeMethod) {
      case 'cash':              return 'All Cash'
      case 'conventional-loan': return 'Conventional'
      case 'va-loan':           return 'VA'
      case 'fha-loan':          return 'FHA'
      case 'usda-loan':          return 'USDA'
      case 'other':             return 'Other'
    }
  },
}
