import Link from 'next/link'
import {MenuItem, type MenuItemProps} from '@mui/material'

import {cs} from '~/utils'

export type HeaderMenuItemProps = MenuItemProps & {
  href?: string
  /*
   * In order for the beforeunload event to fire correctly on the page being
   * navigated to, a standard anchor needs to be used instead of a next/link
   * in order to prevent client side routing.
   */
  preventClientSideRouting?: boolean
  openInNewTab?: boolean
}

/**
 * A menu item to be placed in a popup menu somewhere on the header bar
 */
export function HeaderMenuItem(props: HeaderMenuItemProps) {
  const {className, href, onClick, preventClientSideRouting, openInNewTab, ...rest} = props
  const menuItemClass = cs('HeaderMenuItem', 'font-bold text-blue-800 drag-none', className)

  const openInNewTabProps = openInNewTab ? {target: '_blank', rel: 'noopener noreferrer'} : null

  if (href) {
    if (preventClientSideRouting) {
      return (
        // @ts-ignore
        <MenuItem
          {...openInNewTabProps}
          {...rest}
          component="a"
          className={menuItemClass}
          href={href}
        />
      )
    }

    return (
      <Link href={href} passHref>
        {/* @ts-ignore */}
        <MenuItem {...openInNewTabProps} {...rest} component="a" className={menuItemClass} />
      </Link>
    )
  }

  return <MenuItem {...rest} onClick={onClick} className={menuItemClass} />
}
