import axios from 'axios'

export interface InstanceOptions {
  getAsyncHeaders?: () => Promise<Record<string, string>>
  defaultHeaders?: Record<string, string>
  baseUrl?: string
}

/**
 * Borrowed from Breeze. For our purposes we do not really need this custom instance if we are purely talking
 * to forms' apis and can just always pass the headers as needed
 */
export const createAxiosInstance = ({
  getAsyncHeaders = () => Promise.resolve({}),
  defaultHeaders = {},
  baseUrl,
}: InstanceOptions = {}) => {
  const instance = axios.create({baseURL: baseUrl, headers: defaultHeaders})

  instance.interceptors.request.use(async (config) => ({
    ...config,
    headers: {
      ...(await getAsyncHeaders()),
      ...config.headers,
    },
  }))

  return instance
}

export function redirectToErrorPage(statusCode: number | null | undefined | string) {
  if (statusCode == 403) {
    window.location.replace('/error/forbidden')
  }
  if (statusCode == 404) {
    window.location.replace('/error/not-found')
  }
  if (statusCode == 500) {
    window.location.replace('/error/server')
  }
}
