import {useAtom} from 'jotai'
import {useEffect} from 'react'
import {useQueryClient} from 'react-query'
import {SupportedIDPs} from '@skyslope/offers-shared'
import {useAuth} from '@skyslope/auth-react'

import {syncUserWithMls} from '~/api/mls/sync-user-with-mls'
import {hasSyncedUserProfileWithMlsThisSessionAtom} from '~/state'
import {useAuthHelpers, usePreferredIDP} from '../auth'
import {
  getPreferencesQuery,
  getUserProfileQuery,
  useGetPreferences,
  useGetUserProfile,
} from '../_queries'

/**
 * This hook checks to see if we should autofill a Stellar User's account settings.
 * Reads from a hidden user preference to tell if we need to handle this or not.
 * We do not use the result from this api call, so we can ignore any errors
 */
export function useSyncUserWithMls() {
  const {authState} = useAuthHelpers()
  const {data: userProfile} = useGetUserProfile(undefined, {snackbar: false})
  const {data: userPreferences} = useGetPreferences({snackbar: false})
  const queryClient = useQueryClient()
  const {preferredIDP} = usePreferredIDP()

  const {authState: oktaAuthState} = useAuth()
  const claims = oktaAuthState.idToken?.claims
  const mlsLoginId = claims?.login_id

  const [hasSyncedThisSession, setHasSyncedThisSession] = useAtom(
    hasSyncedUserProfileWithMlsThisSessionAtom,
  )

  useEffect(() => {
    if (authState !== 'logged-in') return
    if (hasSyncedThisSession) return

    if (userPreferences?.hasSyncedUserProfileWithMls && preferredIDP === userPreferences?.idp) {
      setHasSyncedThisSession(true)
      return
    }
    if (userProfile && preferredIDP) {
      syncUser(preferredIDP)
      setHasSyncedThisSession(true)
    }

    async function syncUser(idp: SupportedIDPs) {
      try {
        await syncUserWithMls(idp, mlsLoginId)
        queryClient.invalidateQueries(getPreferencesQuery(userProfile?.id!).queryKey)
        queryClient.invalidateQueries(getUserProfileQuery(userProfile?.id!).queryKey)
      } catch (e: any) {
        console.info('stellar user sync failed')
      }
    }
  }, [
    authState,
    userProfile,
    userPreferences,
    hasSyncedThisSession,
    setHasSyncedThisSession,
    queryClient,
    preferredIDP,
    mlsLoginId,
  ])
}
