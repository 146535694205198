import Script from 'next/script'

/**
 * Initialize Google Analytics
 */
export function GoogleAnalytics(props: {id: string; enableGTag?: boolean}) {
  const {id, enableGTag} = props
  if (enableGTag) {
    return (
      <>
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
        <Script id="gtm">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${id}');
        `}
        </Script>
      </>
    )
  }
  return (
    <>
      <Script id="google-analytics">
        {`
          window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
          ga('create', '${id}', 'auto');
          ga('send', 'pageview');
        `}
      </Script>
      <Script src="https://www.google-analytics.com/analytics.js" />
    </>
  )
}
