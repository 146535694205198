import {Button} from '@mui/material'
import {HelpOutline} from '@mui/icons-material'
import {useAnchorEl} from '@skyslope/mache'

import {cs} from '~/utils'
import type {HtmlElProps} from '~/types'
import {ActionIconBtn, ActionIconBtnProps} from '../action-bar'
import {HeaderMenuItem} from './header-menu-item'
import {HeaderMenu} from './header-menu'

export type HelpBtnProps = HtmlElProps & {
  variant?: ActionIconBtnProps['variant']
}

/**
 * An extensible help button component for the global app header
 */
export function HelpBtn(props: HelpBtnProps) {
  const {className, variant = 'white', ...rest} = props
  const {anchorEl, close, handleClick, isOpen} = useAnchorEl()

  return (
    <span {...rest} className={cs('HelpBtn', 'text-grey-800', className)}>
      <>
        <ActionIconBtn
          className={cs(className, 'lg:hidden')}
          label="Help"
          size="small"
          onClick={handleClick}
          variant={variant}
        >
          <HelpOutline
            fontSize="small"
            className={cs(
              variant === 'white' && 'text-grey-800',
              variant === 'transparent' && 'text-white',
            )}
          />
        </ActionIconBtn>

        <Button
          startIcon={<HelpOutline />}
          className={cs(className, 'hidden lg:inline-flex')}
          onClick={handleClick}
          color="inherit"
        >
          Help
        </Button>
      </>

      <HeaderMenu onClose={close} onClick={close} open={isOpen} anchorEl={anchorEl}>
        <HeaderMenuItem openInNewTab href="https://support.skyslope.com/hc/en-us">
          Support
        </HeaderMenuItem>
        <HeaderMenuItem openInNewTab href="https://skyslope.com/privacy-policy">
          Privacy Policy
        </HeaderMenuItem>
        <HeaderMenuItem openInNewTab href="https://skyslope.com/terms-conditions">
          Terms of Use
        </HeaderMenuItem>
      </HeaderMenu>
    </span>
  )
}
