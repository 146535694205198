import {useQuery, type UseQueryOptions} from 'react-query'
import {type AxiosError} from 'axios'

import {checkUserRole} from '~/api'
import {ErrorCodes} from '~/utils'
import {useErrorReporter} from '../use-error-reporter'

export const checkUserRoleKey = 'use-check-user-role'

/**
 * This query checks to see if the current user has roleId
 */
export function useCheckUserRole(
  isAuthenticated: boolean | undefined,
  roleId: string,
  options: UseQueryOptions<boolean, AxiosError> = {},
) {
  const {reportError} = useErrorReporter(ErrorCodes.CheckUserRole)
  const {queryKey, queryFn} = checkUserRoleQuery(isAuthenticated ?? false, roleId)

  return useQuery<boolean, AxiosError>(queryKey, queryFn, {
    ...options,
    onError(e) {
      options?.onError?.(e)

      // This 404 isn't an error, it just means that the current
      // user doesn't have access to the specified role
      if (e.code != '404') reportError(e)
    },
  })
}

export function checkUserRoleQuery(isAuthenticated: boolean, roleId: string) {
  return {
    queryKey: [checkUserRoleKey, roleId],
    queryFn: () => checkUserRole(isAuthenticated, roleId),
  }
}
